var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" The following table lists various types of colloidal suspensions that can form. For each row, choose the appropriate name for the colloid. ")]),_c('v-simple-table',{staticClass:"mb-2 pl-6",staticStyle:{"max-width":"600px"}},[_c('tbody',[_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Dispersed}$"}}),_c('stemble-latex',{attrs:{"content":"$\\text{Phase}$"}})],1),_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Dispersed}$"}}),_c('stemble-latex',{attrs:{"content":"$\\text{Medium}$"}})],1),_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Name:}$"}})],1)]),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Solid}$"}})],1),_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Gas}$"}})],1),_c('td',[_c('v-select',{staticClass:"mb-0 mt-0",attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select Type"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.SG),callback:function ($$v) {_vm.$set(_vm.inputs, "SG", $$v)},expression:"inputs.SG"}})],1)]),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Solid}$"}})],1),_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Liquid}$"}})],1),_c('td',[_c('v-select',{staticClass:"mb-0 mt-0",attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select Type"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.SL),callback:function ($$v) {_vm.$set(_vm.inputs, "SL", $$v)},expression:"inputs.SL"}})],1)]),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Solid}$"}})],1),_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Solid}$"}})],1),_c('td',[_c('v-select',{staticClass:"mb-0 mt-0",attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select Type"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.SS),callback:function ($$v) {_vm.$set(_vm.inputs, "SS", $$v)},expression:"inputs.SS"}})],1)]),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Liquid}$"}})],1),_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Gas}$"}})],1),_c('td',[_c('v-select',{staticClass:"mb-0 mt-0",attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select Type"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.LG),callback:function ($$v) {_vm.$set(_vm.inputs, "LG", $$v)},expression:"inputs.LG"}})],1)]),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Liquid}$"}})],1),_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Liquid}$"}})],1),_c('td',[_c('v-select',{staticClass:"mb-0 mt-0",attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select Type"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.LL),callback:function ($$v) {_vm.$set(_vm.inputs, "LL", $$v)},expression:"inputs.LL"}})],1)]),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Liquid}$"}})],1),_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Solid}$"}})],1),_c('td',[_c('v-select',{staticClass:"mb-0 mt-0",attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select Type"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.LS),callback:function ($$v) {_vm.$set(_vm.inputs, "LS", $$v)},expression:"inputs.LS"}})],1)]),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Gas}$"}})],1),_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Liquid}$"}})],1),_c('td',[_c('v-select',{staticClass:"mb-0 mt-0",attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select Type"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.GL),callback:function ($$v) {_vm.$set(_vm.inputs, "GL", $$v)},expression:"inputs.GL"}})],1)]),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Gas}$"}})],1),_c('td',[_c('stemble-latex',{attrs:{"content":"$\\text{Solid}$"}})],1),_c('td',[_c('v-select',{staticClass:"mb-0 mt-0",attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select Type"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.GS),callback:function ($$v) {_vm.$set(_vm.inputs, "GS", $$v)},expression:"inputs.GS"}})],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }