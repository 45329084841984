<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The following table lists various types of colloidal suspensions that can form. For each
        row, choose the appropriate name for the colloid.
      </p>

      <v-simple-table class="mb-2 pl-6" style="max-width: 600px">
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{Dispersed}$" />
              <stemble-latex content="$\text{Phase}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Dispersed}$" />
              <stemble-latex content="$\text{Medium}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Name:}$" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{Solid}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Gas}$" />
            </td>
            <td>
              <v-select
                v-model="inputs.SG"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select Type"
                class="mb-0 mt-0"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Solid}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Liquid}$" />
            </td>
            <td>
              <v-select
                v-model="inputs.SL"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select Type"
                class="mb-0 mt-0"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Solid}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Solid}$" />
            </td>
            <td>
              <v-select
                v-model="inputs.SS"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select Type"
                class="mb-0 mt-0"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Liquid}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Gas}$" />
            </td>
            <td>
              <v-select
                v-model="inputs.LG"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select Type"
                class="mb-0 mt-0"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Liquid}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Liquid}$" />
            </td>
            <td>
              <v-select
                v-model="inputs.LL"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select Type"
                class="mb-0 mt-0"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Liquid}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Solid}$" />
            </td>
            <td>
              <v-select
                v-model="inputs.LS"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select Type"
                class="mb-0 mt-0"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Gas}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Liquid}$" />
            </td>
            <td>
              <v-select
                v-model="inputs.GL"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select Type"
                class="mb-0 mt-0"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Gas}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Solid}$" />
            </td>
            <td>
              <v-select
                v-model="inputs.GS"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select Type"
                class="mb-0 mt-0"
              >
                <template #item="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
                <template #selection="{item}">
                  <span class="no-text-transform" v-html="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question419',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        SG: null,
        SL: null,
        SS: null,
        LG: null,
        LL: null,
        LS: null,
        GL: null,
        GS: null,
      },
      items: [
        {
          text: 'Gel',
          value: 'gel',
        },
        {
          text: 'Sol',
          value: 'sol',
        },
        {
          text: 'Emulsion',
          value: 'emulsion',
        },
        {
          text: 'Foam',
          value: 'foam',
        },
        {
          text: 'Aerosol',
          value: 'aerosol',
        },
      ],
    };
  },
};
</script>
